import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
import {
  GetPublicTermsFormAcceptanceResponse,
  GetTermsFormAcceptanceRequest,
  GetTermsFormAcceptanceResponse,
  SaveAcceptedTermsRequest,
} from './terms-acceptance.model';

export const termsAcceptanceApi = restApi
  .enhanceEndpoints({
    addTagTypes: [Tags.TermsAcceptance],
  })
  .injectEndpoints({
    endpoints: builder => {
      const getTermsForAcceptance = builder.query<
        GetTermsFormAcceptanceResponse,
        GetTermsFormAcceptanceRequest
      >({
        query: ({ userId, status }) => ({
          url: `/a/api/terms-acceptance/${userId}/status/${status}`,
          method: HttpMethods.Get,
        }),
        providesTags: [Tags.TermsAcceptance],
      });

      const getPublicTermsForAcceptance = builder.query<GetPublicTermsFormAcceptanceResponse, void>(
        {
          query: () => ({
            url: `/a/api/terms-acceptance/active`,
            method: HttpMethods.Get,
          }),
          providesTags: [Tags.TermsAcceptance],
        }
      );

      const saveAcceptedTerms = builder.mutation<void, SaveAcceptedTermsRequest>({
        query: ({ acceptanceId }) => ({
          url: `/a/api/terms-acceptance/user`,
          method: HttpMethods.Post,
          data: { acceptanceId },
        }),
        invalidatesTags: [Tags.TermsAcceptance],
      });

      return {
        getTermsForAcceptance,
        getPublicTermsForAcceptance,
        saveAcceptedTerms,
      };
    },
  });
